<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Edit Rate Plan</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">Rate Plan Policy (*)</span>
        <v-select
          label="name"
          :options="cancellationPolicies"
          :reduce="policy => policy.code"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="payload.cancellationPolicyType"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("cancellationPolicyType")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <vs-input
          class="w-full"
          label="Rate Plan Name (*)"
          v-model="payload.name"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">Meals (*)</span>
        <v-select
          label="name"
          :options="mealOptions"
          :reduce="meal => meal.code"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="payload.mealPlan"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("mealPlan")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <vs-input
          type="number"
          class="w-full"
          label="Minimum Stay (* Day)"
          v-model="payload.minimumStay"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("minimumStay")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <vs-input
          type="number"
          class="w-full"
          label="Bookable (* Day)"
          v-model="payload.bookable"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("bookable")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <div
          class="vs-component vs-con-input-label vs-input w-full vs-input-primary"
        >
          <label for="" class="vs-input--label">Minimum Price (*)</label>
          <div class="vs-con-input">
            <money
              class="vs-inputx vs-input--input normal"
              style="border: 1px solid rgba(0, 0, 0, 0.2);"
              v-model="payload.minimumPrice"
              v-bind="money"
            />
          </div>
        </div>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("minimumPrice")
        }}</span>
      </vs-col>

      <vs-divider></vs-divider>

      <!-- Product Property Rate Plans Form -->
      <vs-col vs-w="12" class="mt-3">
        <span class="mb-1 block text-lg font-semibold">Product Properties</span>
        <p class="mb-5">select product property which is relate to this rate plan</p>

        <div class="vx-row">
          <div class="vx-col w-full md:w-4/12" v-for="(property, i) in productProperties" :key="i">
            <vs-checkbox
              class="mb-3"
              :vs-value="property.id"
              v-model="payload.roomIds"
            > {{ property.name }} </vs-checkbox>
          </div>
        </div>
      </vs-col>

      <vs-col vs-w="12" class="mt-5">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" @click.prevent="storeData"
            >Submit</vs-button
          >
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Plugin Component
import { Money } from "v-money";

// Store Module
import moduleRatePlanManagement from "@/store/rate-plan-management/moduleRatePlanManagement.js";

export default {
  name: "RatePlanEdit",

  metaInfo: {
    title: "Edit Rate Plan",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect,
    Money
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },

    hotelId() {
      return this.$store.state.userData().userInfo.hotelId;
    },
  },

  data: () => ({
    payload: {
      cancellationPolicyType: "",
      mealPlan: '',
      name: "",
      minimumStay: 0,
      bookable: 0,
      minimumPrice: 0,
      roomIds: [],
    },

    money: {
      decimal: ",",
      thousands: ".",
      prefix: "Rp ",
      masked: false
    },

    loading: true,

    // VSelect Options
    cancellationPolicies: [],
    mealOptions: [],
    productProperties: [],
  }),

  methods: {
    // get all resource that needed for creating rate plan
    async getResource() {
      try {
        const { data: res1 } = await axios.get(`policies/cancellation`);
        this.cancellationPolicies = res1.data.map(policy => ({
          id: policy.id,
          code: policy.code,
          name: policy.name
        }));

        const { data: res2 } = await axios.get(`meal-plans`)
        this.mealOptions = res2.data.map(meal => ({
          id: meal.id,
          code: meal.code,
          name: meal.name
        }));

        const { data: res3 } = await axios.get(`hotels/${this.hotelId}/rooms`)
        this.productProperties = res3.data.rooms.map(property => ({
          id: parseInt(property.id),
          name: property.name
        }));
      } catch (err) {
        this.$catchErrorResponse(err)
      }
    },

    // get selected rate plan
    getData() {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store
        .dispatch("ratePlanManagement/fetchRatePlan", { hotelId: this.hotelId, ratePlanId: this.decryptedID})
        .then(({ data: res }) => {
          this.payload.cancellationPolicyType = res.data.cancellationPolicyType;
          this.payload.mealPlan = res.data.mealPlan;
          this.payload.name = res.data.name;
          this.payload.minimumStay = res.data.minimumStay;
          this.payload.bookable = res.data.bookable;
          this.payload.minimumPrice = res.data.minimumPrice;
          this.payload.roomIds = res.data.rooms.map(v => v.id);
        })
        .catch(err => this.$catchErrorResponse(err))
        .finally(() => {
          this.loading = false
          this.$vs.loading.close()
        })
    },

    // store form
    storeData() {
      const payload = {
        ...this.payload,
        bookable: parseInt(this.payload.bookable),
        minimumStay: parseInt(this.payload.minimumStay),
        roomIds: this.payload.roomIds.map(v => parseInt(v)),
      }

      axios
        .put(`hotels/${this.hotelId}/rate-plans/${this.decryptedID}`, payload)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push({ name: 'vancynet-rate-plan-index' });
        })
        .catch(err => this.$catchErrorResponse(err));
    }
  },

  created() {
    if (!moduleRatePlanManagement.isRegistered) {
      this.$store.registerModule(
        "ratePlanManagement",
        moduleRatePlanManagement
      );
      moduleRatePlanManagement.isRegistered = true;
    }

    this.getResource()
    this.getData()
  }
};
</script>

<style></style>
